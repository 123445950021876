import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorRgba, MaxScreenWidthMobile } from "../../common/foundation/variables";


export const ArticlesContainer = styled.section`
    min-height: 85vh;
    padding: 2.5vh 5vw;
    padding-bottom: 15vh;
`;


export const ArticlesBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const BoxCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    overflow: hidden;
    margin: 2.5vh 0;
    padding: 1%;
    border-radius: 5px;


    img{
        height: 20vh;
        width: auto;
        border-radius: 5px;
    }
    
    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        width: 24%;
        transition: 500ms;
        cursor: pointer;

        :hover{
            filter: drop-shadow(0 5px 10px ${DarkBaseColorRgba(0.5)});
            transform: scale(1.05);
            background: ${BrightBaseColorHex};
        }

        img{
            height: 30vh;
        }
    }

`; 