import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorHex, DarkBaseColorRgba, MaxScreenWidthMobile, SecondaryColorHex } from "../../common/foundation/variables";


export const ArticleContainer = styled.section`
    min-height: 85vh;
    padding: 2.5vh 5vw;
    padding-bottom: 20vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn{
        margin: 5vh 0 2.5vh 0;
        width: auto;
        text-decoration: none;
        background: ${SecondaryColorHex};
        padding: 2.5%;
        border-radius: 5px;
        color: ${BrightBaseColorHex};
        filter: drop-shadow(0 5px 5px ${DarkBaseColorHex});
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        align-items: flex-start;

        .btn{
            filter: drop-shadow(0 0px 0px ${DarkBaseColorHex});
            transition: 500ms;

            :hover{
                filter: drop-shadow(0 5px 5px ${DarkBaseColorRgba(0.6)});
            }
        }

    }
`;

export const ArticleContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;

    .content__header{
        text-align: center;
        width: 80%;
    }

    .content__body{
        display: flex;
        flex-direction: column;

        img{
            max-width: 100%;
            margin-bottom: 2.5vh;
            border-radius: 5px;
        }
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        
        .content__body{
            flex-direction: row;
            gap: 2.5vw;

            img{
                width: 40%;
            }

            p{
                width: 60%;
            }
        }
    }
`;