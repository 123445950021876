import { ArticleContainer, ArticleContent } from "./style";
import articles from "../../assets/moks/articles.json";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BodyRegular, TitleH1 } from "../../common/foundation/typography";
import { useQuery } from "@apollo/client";
import GET__ARTICLE__ID from "../../services/getArticleById";

const ArticlePage = ()=>{
    const {id} = useParams();
    const { loading, error, data } = useQuery(GET__ARTICLE__ID(id));

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :{error}</p>;

    return(
        <ArticleContainer>
            <ArticleContent>
                <div className="content__header">
                    <TitleH1>{data.article.title}</TitleH1>
                    <BodyRegular>{data.article.description}</BodyRegular>
                </div>
                <div className="content__body">
                    <img src={data.article.image.url}/>
                    <BodyRegular>{data.article.text}</BodyRegular>
                </div>
            </ArticleContent>

            <Link className="btn" to="/articles">RETORNAR</Link>
        </ArticleContainer>
    )
}

export default ArticlePage;