import { FooterContainer, FooterInformations, FooterMenu, FooterOverlay } from "./style";
import { ReactComponent as Icon } from "../../assets/svg/icon.svg";
import { BodySmall, TitleH3 } from "../../common/foundation/typography";
import { useState } from "react";
import { Link } from "react-router-dom";

const FooterComponent = ()=>{
    const [show, setShow] = useState(false)

    return(
        <FooterContainer onClick={()=>setShow(!show)} show={show}>
            <Icon 
                onClick={()=>setShow(!show)}
            />
            
            <FooterOverlay show={show} />
            
            <FooterInformations show={show}>
                <BodySmall>Telefone: <a href="" target="__blank">+55118917268391782</a></BodySmall>
                <BodySmall>Instagram: <a href="" target="__blank">@legadovet</a></BodySmall>
            </FooterInformations>

            <FooterMenu show={show}>
                <nav className="nav">
                    <Link to="/"><TitleH3>HOME</TitleH3></Link>
                    <Link to="team"><TitleH3>EQUIPE</TitleH3></Link>
                    <Link to="fields"><TitleH3>ÁREAS</TitleH3></Link>
                    <Link to="articles"><TitleH3>ARTIGOS</TitleH3></Link>
                    <Link to="contact"><TitleH3>CONTATO</TitleH3></Link>
                </nav>
            </FooterMenu>
        </FooterContainer>
    )
}

export default FooterComponent;