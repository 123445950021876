import styled from "styled-components";
import { MaxScreenWidthMobile } from "./variables";


export const TitleH1 = styled.h1`
    font-size: 10vw;

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        font-size: 4vw;
    }
`;

export const TitleH2 = styled.h2`
    font-size: 7.5vw;

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        font-size: 3vw;
    }
`;

export const TitleH3 = styled.h3`
    font-size: 5vw;

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        font-size: 2vw;
    }
`;

export const BodyRegular = styled.p`
    font-size: 3.75vw;

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        font-size: 1.5vw;
    }
`;

export const BodySmall = styled.p`
    font-size: 2.5vw;

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        font-size: 1vw;
    }
`;