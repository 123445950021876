import { BodyRegular, TitleH1 } from "../../common/foundation/typography";
import { ArticlesBox, ArticlesContainer, BoxCard } from "./style"
import articles from "../../assets/moks/articles.json";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import GET_ARTICLES from "../../services/getArticles";

const ArticlesPage = ()=>{
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_ARTICLES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :{error}</p>;

    return(
        <ArticlesContainer>
            <TitleH1>Nossos Artigos</TitleH1>
            <ArticlesBox>
                {data.articles.map(article=>(
                    <BoxCard onClick={()=>navigate(`/article/${article.id}`)} key={article.id}>
                        <img src={article.image.url}/>
                        <BodyRegular>{article.title}</BodyRegular>
                    </BoxCard>
                ))}
            </ArticlesBox>
        </ArticlesContainer>
    )
}

export default ArticlesPage;