import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorRgba, MaxScreenWidthMobile } from "../../common/foundation/variables";

export const HomeContainer =  styled.section`
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5vw;
    padding-bottom: 15vh;

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        padding-bottom: 0;
    }
`;

export const LastArticles =  styled.div`
    display: flex;
    flex-direction: column;
    width:100%;

    .articles-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:5vh;
        padding: 5vh 0;
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px) {
        .articles-box{
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            gap:0;
        }
    }
`;

export const ArticlesCard = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    overflow: hidden;
    border-radius: 5px;
    transition: 500ms;

    img{
        width: auto;
        height: 50vh;
        border-radius: 5px;
    }

    .card__title{
        text-align: center;
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        width: 20%;
        cursor: pointer;

        img{
            height: 30vh;
            max-width: none;
        }

        :hover{
            box-shadow: 0 5px 10px ${DarkBaseColorRgba(0.6)};
            background: ${BrightBaseColorHex};
        }
    }
`;