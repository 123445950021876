
//COLOR HEX
export const BrightBaseColorHex = "#F6F6F6";
export const DarkBaseColorHex = "#2D2D2D";
export const PrimaryColorHex = "#134346";
export const SecondaryColorHex = "#91410A";

//COLOR RGBA
export const BrightBaseColorRgba = opacity => `rgba(246, 246, 246, ${opacity})`;
export const DarkBaseColorRgba = opacity => `rgba(45,45,45, ${opacity})`;
export const PrimaryColorRgba = opacity => `rgba(19, 67, 70, ${opacity})`;
export const SecondaryColorRgba = opacity => `rgba(145,65,10, ${opacity})`;


//BREAK POINTS
export const MaxScreenWidthMobile = 600;
export const MaxScreenWidthTablet = 900;
export const MaxScreenWidthDesktop = 1800;