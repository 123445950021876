import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorHex, MaxScreenWidthMobile, PrimaryColorHex } from "../../common/foundation/variables";


export const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5vh 5vw;
    height: 15vh;

    svg{
        height: 100%;
        width: auto;
    }

    .page-name{
        width: 40%;
        text-align: center;
    }

   @media screen and (min-width:${MaxScreenWidthMobile}px){
    svg{
        height: 100%;
        width: auto;
    }

    .page-name{
        display: none;
    }
   }
`;

export const HeaderMenu =  styled.section`
    display: none;

    @media screen and (min-width:${MaxScreenWidthMobile}px){

        display: flex;
        justify-content: center;
        align-items: center;
    
        nav{
            height: 100%;
            display: flex;
            align-items: center;
            gap: 5vw;
    
            a{
                text-decoration: none;
                color: ${DarkBaseColorHex};
                position: relative;
                transition: 0.5s;
    
                ::after{
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    left: -10%;
                    width: 110%;
                    border: 5px solid ${PrimaryColorHex};
                    opacity: 0;
                    transition: 0.5s;
                }
    
                :hover{
                    color: ${PrimaryColorHex};
    
                    ::after{
                        bottom: -10%;
                        left: -10%;
                        opacity: 1;
                    }
                }
    
            }        
        }
    }
`;