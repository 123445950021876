import { HeaderContainer, HeaderMenu } from "./style";
import {ReactComponent as Logo} from "../../assets/svg/logo.svg";
import { TitleH3 } from "../../common/foundation/typography";
import { Link } from "react-router-dom";
import { memo } from "react";


const HeaderComponent = ()=>{
console.log("recarregado")
    return(
        <HeaderContainer>
            <Logo/>
            <TitleH3 className="page-name">HOME</TitleH3>

            <HeaderMenu>
                <nav>
                    <Link to="/"><TitleH3>HOME</TitleH3></Link>
                    <Link to="team"><TitleH3>EQUIPE</TitleH3></Link>
                    <Link to="fields"><TitleH3>ÁREAS</TitleH3></Link>
                    <Link to="articles"><TitleH3>ARTIGOS</TitleH3></Link>
                    <Link to="contact"><TitleH3>CONTATO</TitleH3></Link>
                </nav>
            </HeaderMenu>
        </HeaderContainer>
    )
}

export default memo(HeaderComponent);