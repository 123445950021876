import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BodyRegular, BodySmall, TitleH2 } from "../../common/foundation/typography"
import GET_ARTICLES from "../../services/getArticles";

import { ArticlesCard, HomeContainer, LastArticles } from "./style";

const HomePage = () => {
    const [articles, setArticles] = useState(false);
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_ARTICLES);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :{error}</p>;

    return(
        <HomeContainer>
            <LastArticles>
                <TitleH2>ÚLTIMOS ARTIGOS:</TitleH2>
                <div className="articles-box">
                    {data.articles.map(article=>{
                        return(
                            <ArticlesCard onClick={()=>navigate(`/article/${article.id}`)}>
                                <img src={article.image.url}/>
                                <BodyRegular className="card__title">{article.title}</BodyRegular>
                                <BodySmall>{article.description}</BodySmall>
                            </ArticlesCard>
                        )
                    })}
                </div>
            </LastArticles>
            
        </HomeContainer>
    )
} 

export default HomePage;