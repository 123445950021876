import { HashRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./common/foundation/globalStyle";
import FooterComponent from "./components/footer";
import HeaderComponent from "./components/header";
import ArticlePage from "./pages/article";
import ArticlesPage from "./pages/articles";
import ContactPage from "./pages/contact";
import FieldsPage from "./pages/fields";
import HomePage from "./pages/home";
import TeamPage from "./pages/team";


function App() {
  return (
    <HashRouter>
      <HeaderComponent/>
      <GlobalStyle/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="team" element={<TeamPage/>}/>
        <Route path="fields" element={<FieldsPage/>}/>
        <Route path="contact" element={<ContactPage/>}/>
        <Route path="articles" element={<ArticlesPage/>}/>
        <Route path="article/:id" element={<ArticlePage/>}/>
      </Routes>
      <FooterComponent/>
    </HashRouter>
  );
}

export default App;
