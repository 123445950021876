import { createGlobalStyle } from "styled-components";
import bodyFont from "../../assets/fonts/body.ttf";
import titleFont from "../../assets/fonts/title.ttf";
import { BrightBaseColorHex, BrightBaseColorRgba, DarkBaseColorHex, PrimaryColorHex, PrimaryColorRgba, SecondaryColorHex } from "./variables";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "bodyFont";
        src: url(${bodyFont}) format("truetype");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "titleFont";
        src: url(${titleFont}) format("truetype");
        font-weight: bold;
        font-style: normal;
    }

    *{
        box-sizing: border-box;
        padding: 0;
        margin:0;
    }

    html{
        background: linear-gradient(135deg, #fff, ${BrightBaseColorRgba(0.9)} 60%, ${PrimaryColorRgba(0.8)}, ${DarkBaseColorHex});
    }

    h1, h2, h3, h4{
        font-family: 'titleFont';
    }

    body{
        font-family: "bodyFont";
    }
`;

export default GlobalStyle;