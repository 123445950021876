import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorHex, DarkBaseColorRgba, MaxScreenWidthMobile, PrimaryColorRgba, SecondaryColorHex, SecondaryColorRgba } from "../../common/foundation/variables";

export const TeamContainer =  styled.div`
    min-height: 85vh;
    padding: 2.5vh 5vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    gap: 2.5vh;

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0;

        .conatainer__title{
            width: 100%;
        }
    }
`;

export const TeamBoxPeople = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 30%;
    }
`;

export const BoxPeopleCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 20%;
    height: 15vh;
    background: ${PrimaryColorRgba(1)};
    border-radius: 5px;
    padding: 1%;
    text-align: center;
    color: ${BrightBaseColorHex};
    filter: grayscale(${props=>props.selected ? 0 : 100}) drop-shadow(0 0px 0px ${DarkBaseColorHex});
    transition: 500ms;

    .img{
        height: 10vh;
        max-width: 100%;
        overflow: hidden;
        position: relative;

        img{
            width: 100%;
        }

        ::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 60%, ${PrimaryColorRgba(1)} 90%);
        }
    }
    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        height: 30vh;
        width: 40%;
        cursor: pointer;
        
        .img{
            height: 20vh;
        }

        :hover{
            filter: grayscale(0) drop-shadow(0 5px 5px ${DarkBaseColorRgba(0.6)});
        }
    }
`;

export const TeamInformation =  styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .info__title{
        width: 100%;
    }

    .info__text{
        width: 60%;
    }

    .info__image{
        width: 30%;
        
        .avatar{
            width: 100%;
            background-color: ${SecondaryColorRgba(0.4)};
            border-radius: 5px;
        }
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        width: 70%;
        justify-content: space-evenly;

        .info__title{
            text-align: center;
        }

        .info__image{
            width: auto;
            height: 50vh;

            .avatar{
                width: auto;
                max-height: 100%;
                padding: 5% 5% 0 5%;
            }
        }
    }
`;