import { gql } from "@apollo/client";

const GET__ARTICLE__ID = (id) => gql`
    query MyQuery{
        article(where: {id: "${id}"}) {
            id
            image {
                url
            }
            text
            title
            description
        }
  }
`;

export default GET__ARTICLE__ID;