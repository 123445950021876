import { gql } from "@apollo/client";

const GET_ARTICLES = gql`
    query Articles {
        articles {
            description
            id
            text
            title
            image {
                url
            }
        }
    }
`;

export default GET_ARTICLES;