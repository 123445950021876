import { BodyRegular, TitleH1 } from "../../common/foundation/typography";
import { ContactContainer, ContactContent } from "./styled"
import {ReactComponent as Instagram} from "../../assets/svg/insta.svg";
import {ReactComponent as Email} from "../../assets/svg/mail.svg";
import {ReactComponent as Wpp} from "../../assets/svg/whats.svg";
import image from "../../assets/images/contact.jpg";
import { useState } from "react";

const ContactPage = ()=>{
    const [contacts, setContacts]=useState([
        {
            name: "@legadoconsulvet",
            icon: <Instagram/>,
            href: "https://www.instagram.com/legado_consulvet/"
        },
        {
            name: "contato@legadovet.com",
            icon: <Email/>,
            href: "mailto:contato@legadovet.com"
        },
        {
            name: "Isaac Ferraz",
            icon: <Wpp/>,
            href: "https://linkwhats.app/06ea95"
        },
        {
            name: "Matheus Baylão",
            icon: <Wpp/>,
            href: "https://linkwhats.app/731521"
        },
        {
            name: "Marco Vinicius Ribeiro",
            icon: <Wpp/>,
            href: "https://linkwhats.app/1133d3"
        },
        {
            name: "João Pedro paiva",
            icon: <Wpp/>,
            href: "https://linkwhats.app/1f8d5a"
        }
    ]);



    return(
        <ContactContainer>
            <TitleH1>Nossos melhores contatos</TitleH1>
            <ContactContent>
                {
                    contacts.map(contact=>(
                        <a href={contact.href} target="__blank" className="content__item">
                            {contact.icon}
                            <BodyRegular>{contact.name}</BodyRegular>
                        </a>
                    ))
                }
            </ContactContent>

            <div className="image">
                <img src={image}/>
            </div>
        </ContactContainer>
    )
}

export default ContactPage;