import styled from "styled-components";
import { BrightBaseColorHex, DarkBaseColorRgba, MaxScreenWidthMobile, PrimaryColorRgba, SecondaryColorRgba } from "../../common/foundation/variables";

export const FieldsContainer = styled.section`
    display: flex;
    flex-direction: column;
    min-height: 85vh;
    padding: 0 5vw;
    padding-bottom: 15vh;
`;

export const FieldsContent = styled.div`
    display: flex;
    gap: 5vh;
    flex-direction: column;

    .content__filter{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        p{
            width: 45%;
            background: ${SecondaryColorRgba(0.4)};
            margin: 2.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 5px;
            padding: 1%;
        }

        .selected{
            background: ${PrimaryColorRgba(1)};
            color: ${BrightBaseColorHex};
        }
    }

    .content__data{
        display: flex;
        flex-direction: column;
        gap: 5vh;

        .data__title{
            text-align: center;
        }
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        flex-direction: row;

        .content__filter{
            flex-direction: column;
            justify-content: space-evenly;

            p{
                width: 100%;
                padding: 1vh 2.5vw;
                cursor: pointer;
                transition: 500ms;

                :hover{
                    box-shadow: 0 5px 10px ${DarkBaseColorRgba(0.6)};
                }
            }
        }
    }
`;