import styled from "styled-components";
import { DarkBaseColorRgba, MaxScreenWidthMobile, PrimaryColorHex } from "../../common/foundation/variables";

export const ContactContainer = styled.div`
    min-height: 85vh;
    padding: 2.5vh 5vw;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    position: relative;

    .image{
        display: none;
    }

    @media screen and (min-width: ${MaxScreenWidthMobile}px){
        .image{
            display: flex;
            position: absolute;
            top: 50%;
            left: 40%;
            transform: translate(0%, -50%);
            width: 50%;

            img{
                max-width: 100%;
                border-radius: 5px;
            }

            ::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background: radial-gradient(transparent, ${PrimaryColorHex});
            }
        }
    }
`;

export const ContactContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .content__item{
        display: flex;
        gap: 2vw;
        align-items: center;
        margin: 2.5vh 0;
        color: ${PrimaryColorHex};
        text-decoration: none;
        width: auto;
        transition: 500ms;

        svg{
            height: 25px;
            width: auto;
            fill: ${PrimaryColorHex};
        }

        .st0{
            stroke: ${PrimaryColorHex};
            stroke-width: 2px;
            fill: none;
        }

        :hover{
            filter: drop-shadow(0 5px 10px ${DarkBaseColorRgba(0.6)});
        }
    }
`;