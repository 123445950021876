import { BodyRegular, BodySmall, TitleH1, TitleH2, TitleH3 } from "../../common/foundation/typography"
import { BoxPeopleCard, TeamBoxPeople, TeamContainer, TeamInformation } from "./style"
import jp from "../../assets/images/jp.png";
import marco from "../../assets/images/marco.png";
import isaac from "../../assets/images/isaac.png";
import matheus from "../../assets/images/matheus.png";
import { useState } from "react";

const TeamPage = ()=>{
    const team = [
        {
            name: "João Pedro de Paiva",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            img: jp
        },
        {
            name: "Matheus Baylão",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            img: matheus
        },
        {
            name: "Marco Vinícius Ribeiro",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            img: marco
        },
        {
            name: "Isaac Ferraz",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            img: isaac
        }
    ];

    const [selected, setSelected] = useState(team[0]);

    return(
        <TeamContainer>
            <TitleH2 className="conatainer__title">CONHECA O TIME LEGADO VET</TitleH2>

            <TeamBoxPeople>
                {team.map(person=>{
                    return(
                    <BoxPeopleCard onClick={()=>setSelected(person)} selected={selected.name==person.name} key={person.name}>
                        <div className="img">
                            <img src={person.img}/>
                        </div>
                        <BodySmall>{person.name}</BodySmall>
                    </BoxPeopleCard>
                    )
                })}
            </TeamBoxPeople>

            <TeamInformation>
                <TitleH3 className="info__title">{selected.name}</TitleH3>
                <div className="info__text">
                    <BodySmall>{selected.description}</BodySmall>
                </div>

                <div className="info__image">
                    <img className="avatar" src={selected.img}/>
                </div>
            </TeamInformation>
        </TeamContainer>
    )
}

export default TeamPage;